import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SubSubTitle from '../components/Titles/SubSubTitle'

export const AboutCard = ({ content, align = 'left' }) => {
  const imageData = getImage(content.image.localFile.childImageSharp)
  const image = (
    <div className="h-[200px] sm:h-auto w-full sm:w-[300px]">
      <GatsbyImage
        image={imageData}
        className="object-cover w-full h-full"
        alt=""
      />
    </div>
  )

  return (
    <article
      key={content.title}
      className={`flex ${
        align === 'left' ? 'flex-col' : 'flex-col-reverse'
      } sm:flex-row w-full mb-10 bg-gray-200`}
    >
      {align === 'left' ? image : null}
      <section className="flex-1 h-full p-10 text-left">
        <SubSubTitle className="text-2xl">{content.title}</SubSubTitle>
        <p className="leading-6">{content.text}</p>
      </section>
      {align === 'right' ? image : null}
    </article>
  )
}

AboutCard.propTypes = {
  align: PropTypes.string,
}

export default AboutCard
