import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import Layout from '../layouts/en'
import AboutCard from '../components/AboutCard'
import SubSubTitle from '../components/Titles/SubSubTitle'

const lang = 'en'

export default class IndexPage extends React.Component {
  render() {
    const { data, location } = this.props
    const { homepage, services } = data
    const logoData = getImage(homepage.logo.localFile.childImageSharp)
    const heroImageData = getImage(
      homepage.hero_image.localFile.childImageSharp
    )
    let colors = ['#1356FF', '#ff5a4b', '#313131', '#ffd063']
    if (
      homepage.color_1 &&
      homepage.color_2 &&
      homepage.color_3 &&
      homepage.color_4
    ) {
      colors = [
        homepage.color_1,
        homepage.color_2,
        homepage.color_3,
        homepage.color_4,
      ]
    }

    const headingTranslations = homepage.translations.find(
      (t) => t.language === lang
    )

    // Chooses white or black text based on contrast
    function useLightText(bgColor) {
      const color =
        bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
      const r = parseInt(color.substring(0, 2), 16) // hexToR
      const g = parseInt(color.substring(2, 4), 16) // hexToG
      const b = parseInt(color.substring(4, 6), 16) // hexToB
      const uicolors = [r / 255, g / 255, b / 255]
      const c = uicolors.map((col) => {
        if (col <= 0.03928) {
          return col / 12.92
        }
        return Math.pow((col + 0.055) / 1.055, 2.4)
      })
      const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
      return L > 0.179 ? false : true
    }

    function getHeroImage() {
      if (!heroImageData) {
        return (
          <StaticImage
            className="w-full h-[300px] sm:h-[600px]"
            src="../assets/img/vr.jpg"
            alt=""
          />
        )
      }
      return (
        <GatsbyImage
          className="w-full h-[300px] sm:h-[600px]"
          image={heroImageData}
          alt=""
        />
      )
    }

    return (
      <Layout location={location}>
        <div className="relative">
          {getHeroImage()}
          <div className="absolute bottom-0 hidden w-full grid-cols-4 sm:grid">
            {services.edges.map((service, i) => {
              service = service.node
              const serviceTranslation = service.translations.find(
                (t) => t.language === lang
              )
              return (
                <div
                  className={`p-4 h-[120px] flex flex-col justify-end`}
                  style={{ backgroundColor: colors[i] }}
                  key={serviceTranslation.title}
                >
                  <SubSubTitle white={useLightText(colors[i])}>
                    {serviceTranslation.title}.
                  </SubSubTitle>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center w-full my-24">
          <GatsbyImage
            className="w-[170px] h-24 mb-10"
            image={logoData}
            alt="Logo FH Salzburg"
          />
          <p className="px-10 leading-relaxed text-center font-text md:px-40">
            {headingTranslations.heading_description}
          </p>
        </div>
        <div className="w-full h-auto text-center">
          {services.edges.map((service, i) => {
            service = service.node
            const alignment = i % 2 ? 'left' : 'right'
            const serviceTranslation = service.translations.find(
              (t) => t.language === lang
            )
            return (
              <React.Fragment key={service.title}>
                <AboutCard
                  content={{
                    title: serviceTranslation.title + '.',
                    text: serviceTranslation.description,
                    image: service.image,
                  }}
                  align={alignment}
                />
              </React.Fragment>
            )
          })}
        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.shape({
      edges: PropTypes.array,
    }),
    sliderProjects: PropTypes.shape({
      edges: PropTypes.array,
    }),
    services: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 170, height: 96)
      }
    }
    homepage: directusPage(title: { eq: "Homepage" }) {
      translations {
        heading
        heading_description
        language
      }
      title
      color_1
      color_2
      color_3
      color_4
      hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600)
          }
        }
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500)
          }
        }
      }
    }
    services: allDirectusService {
      edges {
        node {
          title
          translations {
            title
            description
            language
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1050)
              }
            }
          }
        }
      }
    }
  }
`
